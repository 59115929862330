import HTTP from './http';
import settings from '@/settings.js';
import eventBus from '../event-bus';
import router from '../router';

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
    return `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`;
  }).join(''));

  return JSON.parse(jsonPayload);
}

class API extends HTTP {
  constructor() {
    super();
    this.authInfo = {};
    const authInfo = localStorage.getItem('authInfo');
    if (authInfo) {
      this.authInfo = JSON.parse(authInfo);
    }
    this.http.interceptors.request.use(this.requestInterceptor.bind(this));

    // todo this might be potential issue
    this.refreshInterval = setInterval(
      this.refreshToken.bind(this),
      5 * 60 * 1000, // 5 minutes
    );
  }

  requestInterceptor(config) {
    if (this.authInfo?.IdToken) {
      config.headers.Authorization = this.authInfo.IdToken;
    }
    return config;
  }

  setAuthInfo(authInfo) {
    localStorage.setItem('authInfo', JSON.stringify(authInfo));
    this.authInfo = authInfo;
    console.log('authInfo update', this.authInfo);
  }

  refreshToken() {
    if (!this.authInfo?.IdToken) return;
    const idToken = parseJwt(this.authInfo.IdToken);
    this.post(
      `${settings.COGNITO_GATEWAY_URL}/serverless/refresh`,
      null,
      {
        username: idToken['cognito:username'],
        refresh_token: this.authInfo.RefreshToken,
      },
    ).then((response) => {
      console.log(response);
      if (this.authInfo && response.AccessToken) {
        Object.assign(this.authInfo, response);
        this.setAuthInfo(this.authInfo);
      }
    }).catch((error) => {
      if (error.message) {
        router.push({
          name: 'Logout',
        });
      }
    });
  }

  // --- clients ---------------------------------------------------------------

  async getClients(data, cancelToken) {
    //tested need to implement properly
    // var session_main=""
    // const name="session"
    // const value = `; ${document.cookie}`;
    // const parts = value.split(`; ${name}=`);
    // if (parts.length === 2) {
    //   session_main =  parts.pop().split(';').shift();
    // }
    // const myHeaders = new Headers();
    // myHeaders.append("sessionid", session_main);
    // myHeaders.append("Content-Type", "application/json");
    // const raw = JSON.stringify({
    //   "hospitalID": 2
    // });
    // const requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow"
    // };
    // fetch("https://apipracticegwdev.healent.com/navigator/v-nurse/care-protocol/hospital?foobar=baz", requestOptions)
    //   .then((response) => response.text())
    //   .then((result) => console.log(result))
    //   .catch((error) => console.error(error));

    // this.post(`${settings.BACKEND_URL}/api/v-nurse/clients`, null, data, cancelToken);
    return this.post(`${settings.BACKEND_URL}/api/v-nurse/clients`, null, data, cancelToken);
  }

  async searchClients(data, cancelToken) {
    console.log('data', data);
    return this.post(`${settings.BACKEND_URL}/api/v-nurse/v2/search-clients`, null, data, cancelToken);
  }

  async getClient(id, cancelToken) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/client/${id}`, null, cancelToken);
  }

  async getClientformdata(id, cancelToken) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/client/form-data/${id}`, null, cancelToken);
  }

  async getClientInactiveReasons(cancelToken) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/client/client-inactive-reasons`, null, cancelToken);
  }

  async createClient(data, cancelToken) {
    const response = await this.getRunningEnviroment(cancelToken);
    const baseUrl =
      response?.data?.environment === 'development'
        ? `${settings.BACKEND_API_PRACTICE_URL}/navigator/clients/new`
        : `${settings.BACKEND_URL}/api/v3-nurse/client/new`;
    return this.postMultipart(baseUrl, null, data, cancelToken);
    // var session_main=""
    // const name="session"
    // const value = `; ${document.cookie}`;
    // const parts = value.split(`; ${name}=`);
    // if (parts.length === 2) {
    //   session_main =  parts.pop().split(';').shift();
    // }
    // const myHeaders = new Headers();
    // myHeaders.append("sessionid", session_main);
    // myHeaders.append("Content-Type", "application/json");
    // const raw = JSON.stringify(
    //   {
    //     "client_id":19091,
    //     "checklist_id":198012,
    //     "value":12.0
        
    //     }
    // );
    // const requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow"
    // };
    // fetch("https://apipracticegwdev.healent.com/navigator/clients/new", requestOptions)
    //   .then((response) => response.text())
    //   .then((result) => console.log(result))
    //   .catch((error) => console.error(error));
  }
  async upsertClient(data, cancelToken) {
    return this.postMultipart(
      `${settings.BACKEND_URL}/api/v-nurse/client/upsert`,
      null,
      data,
      cancelToken,
    );
  }
  async upsertClientWithSurvey(data, cancelToken) {
    return this.postMultipart(
      `${settings.BACKEND_URL}/api/v-nurse/client/upsert-with-survey`,
      null,
      data,
      cancelToken,
    );
  }

  // eslint-disable-next-line camelcase
  async upsertClientWith_RPM_RTM_Survey(data, cancelToken) {
    return this.postMultipart(
      `${settings.BACKEND_URL}/api/v-nurse/client/upsert-with-rpm-rtm-survey`,
      null,
      data,
      cancelToken,
    );
  }

  async activateClient(data, cancelToken) {
    return this.postMultipart(
      `${settings.BACKEND_URL}/api/v-nurse/client/activate`,
      null,
      data,
      cancelToken,
    );
  }

  async searchClient(search, cancelToken) {
    const params = {
      q: search,
    };
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/search-client`, params, cancelToken);
  }

  async searchICD(params, cancelToken) {
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/diagnose-icd-lookup`,

      params,
      cancelToken,
    );
  }

  async searchPhone(phoneNo, cancelToken) {
    const params = {
      phoneNo,
    };
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/search-phone`, params, cancelToken);
  }

  async importAppointments(data, cancelToken) {
    return this.postMultipart(
      `${settings.BACKEND_URL}/api/v-nurse/client/import-appointments`,
      null,
      data,
      cancelToken,
    );
  }

  async getClientsActivity(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/clients/patient-activity`,
      null,
      data,
      cancelToken,
    );
  }

  async getCountActivePatientLogs(clientId, cancelToken) {
    const params = { clientId };
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/clients/active-logs-count`,
      null,
      params,
      cancelToken,
    );
  }

  async getClientAlertsCategories(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/clients/alert-categories`,
      null,
      data,
      cancelToken,
    );
  }

  async getClientAlert(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/clients/get-client-alert`,
      null,
      data,
      cancelToken,
    );
  }

  async updateClientAlert(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/update-client-alert`,
      null,
      data,
      cancelToken,
    );
  }

  // --- communication ---

  async saveClientCommunicationNotes(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/client/communication-notes`,
      null,
      data,
      cancelToken,
    );
  }

  async saveClientCommunicationNote(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/client/communication-note`,
      null,
      data,
      cancelToken,
    );
  }

  async deleteClientCommunicationNote(data, cancelToken) {
    return this.delete(
      `${settings.BACKEND_URL}/api/v-nurse/client/communication-note`,
      null,
      data,
      cancelToken,
    );
  }

  async saveClientCommunicationsDone(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/client/communication-done`,
      null,
      data,
      cancelToken,
    );
  }

  // --- diet ------------------------------------------------------------------

  async getClientDietData(id, cancelToken) {
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/diet/client/${id}`,
      null,
      cancelToken,
    );
  }

  async updateClientDietData(id, data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/diet/client/${id}`,
      null,
      data,
      cancelToken,
    );
  }

  async generateClientMealPlan(clientId, date, offset, fallback, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/diet/generate-meal-plan/${clientId}`,
      null,
      { date, offset, fallback },
      cancelToken,
    );
  }

  async saveDietDayNote(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/diet/save-note`,
      null,
      data,
      cancelToken,
    );
  }

  async saveDietFreeMeal(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/diet/save-free-meal`,
      null,
      data,
      cancelToken,
    );
  }

  // --- recipe ----------------------------------------------------------------

  async getRecipe(id, cancelToken) {
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/diet/recipe/${id}`,
      null,
      cancelToken,
    );
  }

  async updateRecipe(id, data, cancelToken) {
    return this.postMultipart(
      `${settings.BACKEND_URL}/api/v-nurse/diet/recipe/${id}`,
      null,
      data,
      cancelToken,
    );
  }

  async searchIngredient(search, cancelToken) {
    const params = {
      q: search,
    };
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/diet/usda-ingredient/search`,
      params,
      cancelToken,
    );
  }

  // --- care-protocol ---------------------------------------------------------

  async getCareProtocols(hospitalId, params, cancelToken) {
    let path = `${settings.BACKEND_URL}/api/v-nurse/care-protocols`;
    if (hospitalId) {
      path += `/hospital/${hospitalId}`;
    }
    return this.get(path, params, cancelToken);
  }

  async getCareProtocol(id, userId, cancelToken) {
    let path = `${settings.BACKEND_URL}/api/v-nurse/care-protocols/${id}`;
    if (userId) {
      path += `/user/${userId}`;
    }
    return this.get(path, null, cancelToken);
  }

  async updateCareProtocol(id, userId, data, cancelToken) {
    let path = `${settings.BACKEND_URL}/api/v-nurse/care-protocols/${id}`;
    if (userId) {
      path += `/user/${userId}`;
    }
    return this.post(path, null, data, cancelToken);
  }

  async createCareProtocolItem(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/care-protocols/create-item`,
      null,
      data,
      cancelToken,
    );
  }

  async getCareProtocolItems(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/care-protocols-items/all`,
      null, data, cancelToken,
    );
  }

  async cloneCareProtocolItems(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/care-protocols-items/clone`,
      null, data, cancelToken,
    );
  }

  async updateCareProtocolItem(id, data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/care-protocols-items/${id}`,
      null,
      data,
      cancelToken,
    );
  }

  async deleteCareProtocolItem(id, cancelToken) {
    return this.delete(
      `${settings.BACKEND_URL}/api/v-nurse/care-protocols-items/${id}`,
      null,
      cancelToken,
    );
  }


  async updateClientCareProtocolItem(id, data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/client-care-protocol-items/${id}`,
      null,
      {
        ...data,
        include_notifications_only: true,
      },
      cancelToken,
    );
  }

  async alertSendNotification(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/alert/send-notification`,
      null,
      data,
      cancelToken,
    );
  }

  async updateOnboardingScreen(careProtocolId, onboardingScreen, cancelToken) {
    const path = `${settings.BACKEND_URL}/api/v-nurse/care_protocol/onboarding_screen`;
    const data = {
      careProtocolId,
      onboardingScreen,
    };
    return this.post(path, null, data, cancelToken);
  }


  // --- therapy-pt ------------------------------------------------------------
  async getTherapyPtTable(data, cancelToken) {
    return this.post(`${settings.BACKEND_URL}/api/v-nurse/therapy-pt-table`, null, data, cancelToken);
  }

  async getTherapyPtOptions(params, cancelToken) {
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/therapy-pt-options`,
      params,
      cancelToken,
    );
  }

  async getTherapyPtEntries(params, cancelToken) {
    params.showAll = true;
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/therapy-pt-entries`,
      params,
      cancelToken,
    );
  }

  async setTherapyPtEntries(params, data, cancelToken) {
    params.showAll = true;
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/therapy-pt-entries`,
      params,
      data,
      cancelToken,
    );
  }

  // --- staff members ---------------------------------------------------------

  async getStaffMembers(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/staff/get-members`, null, data, cancelToken,
    );
  }

  async getStaffMember(id, cancelToken) {
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/staff/members/get/${id}`, null, cancelToken,
    );
  }

  async updateStaffMember(data, cancelToken) {
    return this.postMultipart(
      `${settings.BACKEND_URL}/api/v-nurse/staff/members/update`,
      null,
      data,
      cancelToken,
    );
  }

  async searchSeargeon(params, cancelToken) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/search-surgeon`, params, cancelToken);
  }

  async searchPhysiotherapist(params, cancelToken) {
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/search-physiotherapist`, params, cancelToken,
    );
  }

  async searchStaffMemberGlobal(params, cancelToken) {
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/search-staff-member-global`, params, cancelToken,
    );
  }

  async searchNurse(params, cancelToken) {
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/search-nurse`, params, cancelToken,
    );
  }

  // --- meetings --------------------------------------------------------------

  async getMeetings(data, cancelToken) {
    return this.post(`${settings.BACKEND_URL}/api/v-nurse/meetings/get`, null, data, cancelToken);
  }

  async getMeeting(id, cancelToken) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/meetings/get/${id}`, null, cancelToken);
  }

  async saveMeetingNote(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/meetings/save-note`, null, data, cancelToken,
    );
  }

  async updateMeetingRunningTimeCounter(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/meetings/update-running-time`, null, data, cancelToken,
    );
  }

  async createMeeting(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/meetings/create`,
      null,
      data,
      cancelToken,
    );
  }

  async deleteMeeting(meetingId, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/meetings/delete`,
      null,
      {
        meeting_id: meetingId,
      },
      cancelToken,
    );
  }

  async getMeetingByCode(side, meetingCode, userId, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/video-meeting/get`,
      null,
      {
        side,
        'meeting-code': meetingCode,
        'user-id': userId,
      },
      cancelToken,
    );
  }

  async meetingKeepAlive(meetingCode, userId, cancelToken) {
    return this.get(
      `${settings.BACKEND_URL}/video-meeting/alive`,
      {
        'meeting-code': meetingCode,
        'user-id': userId,
      },
      cancelToken,
    );
  }

  async meetingLeave(meetingCode, userId, cancelToken) {
    return this.get(
      `${settings.BACKEND_URL}/video-meeting/leave`,
      {
        'meeting-code': meetingCode,
        'user-id': userId,
      },
      cancelToken,
    );
  }

  async meetingNotifyAboutCall(meetingCode, userId, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/video-meeting/notify-user-about-meeting`,
      null,
      {
        meetingCode,
        userId,
      },
      cancelToken,
    );
  }

  async meetingCancelCall(meetingCode, userId, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/video-meeting/mobile-call-canceled`,
      null,
      {
        meetingCode,
        userId,
      },
      cancelToken,
    );
  }

  // --- education -------------------------------------------------------------

  async getEducationEntries(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/learns-table`, null, data, cancelToken,
    );
  }

  async addEducationEntries(id, entryList, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/learn-collection/${id}/add-entries`,
      null,
      {
        entry_ids: entryList,
      },
      cancelToken,
    );
  }

  async deleteEducationEntry(collectionId, entryId, cancelToken) {
    return this.delete(
      `${settings.BACKEND_URL}/api/v-nurse/learn-collection/${collectionId}/entry/${entryId}`,
      null,
      cancelToken,
    );
  }

  async getEducationEntry(id, cancelToken) {
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/learn/${id}`,
      null,
      cancelToken,
    );
  }

  async updateEducationEntry(id, data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/learn/${id}`,
      null,
      data,
      cancelToken,
    );
  }

  async createEducationEntry(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/learn/create`,
      null,
      data,
      cancelToken,
    );
  }

  async getEducationCollection(id, userId, cancelToken) {
    let path = `${settings.BACKEND_URL}/api/v-nurse/learn-collection/${id}`;
    if (userId) {
      path += `/user/${userId}`;
    }
    return this.get(path, null, cancelToken);
  }

  async updateEducationCollection(id, userId, data, cancelToken) {
    let path = `${settings.BACKEND_URL}/api/v-nurse/learn-collection/${id}`;
    if (userId) {
      path += `/user/${userId}`;
    }
    return this.post(path, null, data, cancelToken);
  }

  // --- Though of the day -----------------------------------------------------

  async getThoughtsOfTheDay(careProtocolId, doctorId, cancelToken) {
    let path = `${settings.BACKEND_URL}/api/v-nurse/care-protocol/${careProtocolId}/thought-of-the-day`;
    if (doctorId) {
      path += `/user/${doctorId}`;
    }
    return this.get(path, null, cancelToken);
  }

  async getClientThoughtsOfTheDay(clientId, cancelToken) {
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/care-protocol-client/thought-of-the-day/${clientId}`,
      null,
      cancelToken,
    );
  }

  async saveThoughtOfTheDay(careProtocolId, doctorId, data, cancelToken) {
    let path = `${settings.BACKEND_URL}/api/v-nurse/care-protocol/${careProtocolId}/thought-of-the-day`;
    if (doctorId) {
      path += `/user/${doctorId}`;
    }
    return this.post(path, null, data, cancelToken);
  }

  async deleteThoughtOfTheDay(careProtocolId, doctorId, itemId, cancelToken) {
    let path = `${settings.BACKEND_URL}/api/v-nurse/care-protocol/${careProtocolId}/thought-of-the-day/delete`;
    if (doctorId) {
      path += `/user/${doctorId}`;
    }

    return this.post(path, null, { id: itemId }, cancelToken);
  }

  async enableThoughtOfTheDayResend(itemId, clientId, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/care-protocol-client/thought-of-the-day-entry/enable-resend`,
      null,
      {
        id: itemId,
        clientId,
      },
      cancelToken,
    );
  }

  async copyClientThoughtsOfTheDay(doctorId, data, cancelToken) {
    let path = `${settings.BACKEND_URL}/api/v-nurse/care-protocol-copy-thoughts-of-the-day`;
    if (doctorId) {
      path += `/user/${doctorId}`;
    }
    return this.post(path, null, data, cancelToken);
  }

  // --- assesments ------------------------------------------------------------

  async getAssesments(data, cancelToken) {
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/assesments/all`,
      data,
      cancelToken,
    );
  }


  async getCpAssesments(params, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/assesments/care_protocol`,
      null,
      params,
      cancelToken,
    );
  }

  async saveCpAssesments(params, cancelToken) {
    return this.patch(
      `${settings.BACKEND_URL}/api/v-nurse/assesments/care_protocol`,
      null,
      params,
      cancelToken,
    );
  }

  async getClientAssessmentsInsights(params, cancelToken) {
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/assessments/client-assessments/insights`,
      params,
      cancelToken,
    );
  }

  async getClientAssessments(params, cancelToken) {
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/assessments/client-assessments`,
      params,
      cancelToken,
    );
  }

  async getClientAssessment(clientAssessmentId) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/assessments/client-assessments/${clientAssessmentId}`);
  }

  async saveClientAssessment(data) {
    return this.post(`${settings.BACKEND_URL}/api/v-nurse/assessments/client-assessments`, null, data);
  }

  async updateClientAssessments(params, cancelToken) {
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/assesments/update_client_assessments`,
      params,
      cancelToken,
    );
  }

  async sendClientAssesmentsReminder(id, cancelToken) {
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/assesments/client/trigger-reminder`,
      { id },
      cancelToken,
    );
  }

  async updateAssessmentQuestions(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/assesments/update_assessment_questions`,
      null,
      data,
      cancelToken,
    );
  }

  async createEmptyAssessment(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/assesments/create`,
      null,
      data,
      cancelToken,
    );
  }

  async deleteAssessment(data, cancelToken) {
    return this.delete(
      `${settings.BACKEND_URL}/api/v-nurse/assesments/delete`,
      null,
      data,
      cancelToken,
    );
  }

  // --- checklist -------------------------------------------------------------

  // -- general --

  async updateClientChecklistItem(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/client-checklist/item/update`,
      null,
      data,
      cancelToken,
    );
  }

  // --- chat ------------------------------------------------------------------

  async searchChatRoom(search, cancelToken) {
    const params = {};
    if (typeof search === 'string') {
      params.q = search;
    } else {
      params.id = search;
    }
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/search-chart-room`,
      params,
      cancelToken,
    );
  }

  async getChatData(cancelToken) {
    return
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/chat/get-data`,
      null, cancelToken,
    );
  }

  // --- compliance ------------------------------------------------------------

  async complianceProtocol(cancelToken) {
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/reports/protocol-compliance`,
      null, cancelToken,
    );
  }

  async compliancePt(cancelToken) {
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/reports/pt-compliance`,
      null, cancelToken,
    );
  }

  async complianceEducation(cancelToken) {
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/reports/education-compliance`,
      null, cancelToken,
    );
  }

  async engagementReport(cancelToken) {
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/reports/client-engagement`,
      null, cancelToken,
    );
  }

  // --- dashboard -------------------------------------------------------------

  async dashboard(cancelToken) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/dash-info`, null, cancelToken);
  }

  // --- authantication --------------------------------------------------------

  async register(data, cancelToken) {
    return this.post(`${settings.BACKEND_URL}/api/v-nurse/register`, null, data, cancelToken);
  }

  async login(data, cancelToken) {
    return this.post(`${settings.BACKEND_URL}/api/v-nurse/login`, null, data, cancelToken);
  }

  async logout(cancelToken) {
    this.setAuthInfo({});
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/logout`, null, cancelToken);
  }

  async getCurrentSessionData(cancelToken) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/current-session-data`, null, cancelToken);
  }

  async getHospitalCampaigns(hospitalId, params, cancelToken) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/hospital-campaigns/${hospitalId}`, params, cancelToken);
  }

  async getHospitalsList(params, cancelToken) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/hospitals-list`, params, cancelToken);
  }

  async getAssignedNursesInCurrentHospital(params, cancelToken) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/check_assigned_nurses_in_current_hospital`, params, cancelToken);
  }

  async getRolesList(cancelToken) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/roles-list`, null, cancelToken);
  }

  async createUserInvite(data, cancelToken) {
    return this.post(`${settings.BACKEND_URL}/api/v-nurse/create-invite`, null, data, cancelToken);
  }

  async getUserInvite(code, cancelToken) {
    const data = {
      code,
    };
    return this.post(`${settings.BACKEND_URL}/api/v-nurse/get-invite`, null, data, cancelToken);
  }

  async createPasswordRestore(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/create-password-restore`, null, data, cancelToken,
    );
  }

  async getPasswordRestore(code, cancelToken) {
    const data = {
      code,
    };
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/get-password-restore`, null, data, cancelToken,
    );
  }

  async usePasswordRestore(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/use-password-restore`, null, data, cancelToken,
    );
  }

  async saveUserPublicKey(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/security/save-public-key`, null, data, cancelToken,
    );
  }

  async getUserPublicKeys(params, cancelToken) {
    return this.get(
      `${settings.BACKEND_URL}/api/v-nurse/security/get-public-keys`, params, cancelToken,
    );
  }

  async getRaw(path, params, cancelToken) {
    return this.get(
      `${settings.BACKEND_URL}${path}`, params, cancelToken,
    );
  }

  async revokeCalendarPermission(params, cancelToken) {
    return this.post(`${settings.BACKEND_URL}/api/v-nurse/google-calendar-revoke`, params, cancelToken);
  }

  async getDownloadAllReportsOrdersCount(cancelToken) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/downloadallreports/get/count`, null, cancelToken);
  }

  async getDownloadAllReportsOrders(data, cancelToken) {
    return this.post(`${settings.BACKEND_URL}/api/v-nurse/downloadallreports/`, null, data, cancelToken);
  }

  async getDownloadAllReportsOrdersGetPDF(reportOrderId, cancelToken) {
    this.downloadFile(`${settings.BACKEND_URL}/api/v-nurse/downloadallreports/get/${reportOrderId}`, null, true);
  }

  async scheduleDownloadOrder(url, payload, cancelToken) {
    if (!payload) {
      return this.get(url, null, cancelToken);
    }
    return this.http.post(url, payload);
  }

  async downloadFile(url, payload, fileName, downloadFromS3 = false) {
    let response = null;
    let filename = '';

    try {
      response = !payload
        ? await this.http.get(url, { responseType: 'blob', timeout: 100000 })
        : await this.http.post(url, payload, { responseType: 'blob', timeout: 100000 });

      filename = response.headers['content-disposition'].split('filename=')[1];
      if (!downloadFromS3) filename = filename.substr(1, filename.length - 2);
    } catch (e) {
      $.notify('Requested file could not be downloaded', {
        position: 'top center',
        className: 'error',
      });
      return;
    }

    try {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([response.data], {
            type: response.headers['content-type'],
          }),
          fileName || filename,
        );
      } else {
        const path = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers['content-type'],
          }),
        );
        const link = document.createElement('a');
        link.href = path;
        link.setAttribute('download', fileName || filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (Exception) {
      $.notify('An error occurred in download process', {
        position: 'top center',
        className: 'error',
      });
    } finally {
      eventBus.$emit('downloadFileOperationEnded', {});
    }
  }

  async getRunningEnviroment(cancelToken) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/get-running-env`, cancelToken);
  }

  async addCheckListItemReadingFromDashboard(data, cancelToken) {
    return this.post(`${settings.BACKEND_URL}/api/v-nurse/client-checklist/add-from-dashboard`, null, data, cancelToken);
  }

  async getPhysicalPT(id, cancelToken) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/therapy-pts/${id}`, null, cancelToken);
  }

  async createPhysicalPT(data, cancelToken) {
    return this.post(`${settings.BACKEND_URL}/api/v-nurse/therapy-pts`, null, data, cancelToken);
  }

  async updatePhysicalPT(id, data, cancelToken) {
    return this.patch(`${settings.BACKEND_URL}/api/v-nurse/therapy-pts/${id}`, null, data, cancelToken);
  }

  async getAllPatientsStats() {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/get_all_patients_stats`);
  }

  async publishBillingReport(data, cancelToken) {
    return this.post(`${settings.BACKEND_URL}/api/v-nurse/client/publish-billing-report`, null, data, cancelToken);
  }

  async getBillingDocuments(params) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/billing/documents`, params);
  }

  async uploadBillingDocument(data) {
    const formData = new FormData();
    const jsonData = {};
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    formData.append('json', JSON.stringify(jsonData));
    return (await this.request('post', `${settings.BACKEND_URL}/api/v-nurse/billing/upload`, null, formData)).data;
  }

  async getBillingHospitals() {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/billing/hospitals`);
  }

  async downloadBillingDocument(documentId, name) {
    try {
      const response = await this.http.get(`${settings.BACKEND_URL}/api/v-nurse/billing/download/${documentId}`,
        { responseType: 'blob', timeout: 100000 });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      console.log('error', e);
      let msg = 'An error occurred in download process';
      if (e.status === 404) {
        msg = 'File not found';
      } else if (e.status === 403) {
        msg = "You don't have permission to see this file";
      }

      $.notify(msg, {
        position: 'top center',
        className: 'error',
      });
    }
  }

  async deleteBillingDocument(documentId) {
    return this.delete(`${settings.BACKEND_URL}/api/v-nurse/billing/download/${documentId}`);
  }

  async createSurgeryInfo(data) {
    return this.post(`${settings.BACKEND_URL}/api/v-nurse/surgery-info`, null, data);
  }

  async getSurgeryInfoList(clientId, params) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/client/${clientId}/surgery-info`, params);
  }

  async updateSurgeryInfo(id, data) {
    return this.patch(`${settings.BACKEND_URL}/api/v-nurse/surgery-info/${id}`, null, data);
  }

  async deleteSurgeryInfo(id) {
    return this.delete(`${settings.BACKEND_URL}/api/v-nurse/surgery-info/${id}`);
  }

  async getClientCPAssessments(clientId, params) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/client/${clientId}/care-protocol-assessments`, params);
  }

  async updateClientCPAssessments(clientId, data) {
    return this.post(`${settings.BACKEND_URL}/api/v-nurse/client/${clientId}/care-protocol-assessments`, null, data);
  }

  async createNudgingRecording(data) {
    return this.post(`${settings.BACKEND_URL}/api/v-nurse/nudging-recordings`, null, data);
  }

  async getNudgingRecordingList(params) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/nudging-recordings`, params);
  }

  async updateNudgingRecording(id, data) {
    return this.patch(`${settings.BACKEND_URL}/api/v-nurse/nudging-recordings/${id}`, null, data);
  }

  async deleteNudgingRecording(id) {
    return this.delete(`${settings.BACKEND_URL}/api/v-nurse/nudging-recordings/${id}`);
  }

  async getHospitalStuff(params) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/get_hospital_staff/`, params);
  }

  async getScoreData(clientId, isFullJourney) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/${clientId}/hif-score`, { is_full_journey: isFullJourney });
  }

  async getWellnessData(clientId, isFullJourney) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/${clientId}/hif-score-wellness`, { is_full_journey: isFullJourney });
  }

  async getRecordData(params) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/checklist/get-records`, params);
  }

  async getPatientTips(params) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/ai/get-patient-tips/`, params);
  }

  async getClientSubmit(params) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/microprotocols/questions/get-client-submit`, params);
  }

  async getClientQuestions(params) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/microprotocols/client-questions`, params);
  }

  async updateClientQuestion(data) {
    return this.post(`${settings.BACKEND_URL}/api/v-nurse/microprotocols/questions/submit`, null, data);
  }

  async getClientIntakeAssessment(params) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/assessments/client-intake`, params);
  }

  async getHospitalCareProtocols(params) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/care-protocol/hospital`, params);
  }

  async createFormBuilder(data) {
    const formData = new FormData();
    const jsonData = {};
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    formData.append('json', JSON.stringify(jsonData));
    return (await this.request('post',
      `${settings.BACKEND_URL}/api/v-nurse/formio/create`, null, formData)).data;
  }

  async getForm(id) {
    return this.get(`${settings.BACKEND_URL}/api/v-nurse/formio/${id}`, null);
  }

  async updateFormBuilder(data) {
    return (await this.request('put',
      `${settings.BACKEND_URL}/api/v-nurse/formio/${data.id}`, null, data)).data;
  }

  async getForms(data, cancelToken) {
    return this.post(
      `${settings.BACKEND_URL}/api/v-nurse/formios`, null, data, cancelToken,
    );
  }
}

export default {
  install: (Vue) => {
    // eslint-disable-next-line
    Vue.prototype.$api = new API();
  },
};
