<template>
  <div class="h-padding-top-l h-padding-left-l h-padding-right-l">
    <div :class="{ 'h-hide': isFullscreen, 'h-AllPatients-filter h-margin-bottom-l': true }">
      <div class="h-AllPatients-filter-card card-activity flex-1">
        <!-- Priority Checkboxes -->
        <div
          class="FilterItem h-filter-item-head">
          <h4 class="h-activity-filter-text">
            Priority
          </h4>
        </div>
        <div class="h-filter-item-sub">
          <div v-for="priority in priorityOptions" :key="priority" class="h-btn-check h-cursor-pointer">
            <input
              type="checkbox"
              :id="'priority-' + priority"
              :value="priority"
              v-model="filters.priority"
              @change="applyFilters" />
            <label
              class="btn btn-default"
              :for="'priority-' + priority"><i></i> {{ priority.charAt(0).toUpperCase() + priority.slice(1) }}</label>
          </div>
        </div>
      </div>
      <div class="h-AllPatients-filter-card card-activity">
        <!-- Status Checkboxes -->
        <div
          class="FilterItem h-filter-item-head">
          <h4 class="h-activity-filter-text">
            Status
          </h4>
        </div>
        <div class="h-filter-item-sub">
          <div v-for="status in statusOptions" :key="status" class="h-btn-check h-cursor-pointer">
            <input
              type="checkbox"
              :id="'status-' + status"
              :value="status"
              v-model="filters.alertStatus"
              @change="applyFilters" />
            <label
              class="btn btn-default"
              :for="'status-' + status"><i></i> {{ statusLabelMap[status] }}</label>
          </div>
        </div>
      </div>

      <div class="h-AllPatients-filter-card card-activity flex-1">
        <!-- Category Checkboxes -->
        <div
          class="FilterItem h-filter-item-head">
          <h4 class="h-activity-filter-text">
            Category
          </h4>
        </div>
        <div class="h-filter-item-sub">
          <div v-for="category in categoryOptions" :key="category" class="h-btn-check h-cursor-pointer">
            <input
              type="checkbox"
              :id="'category-' + category"
              :value="category"
              v-model="filters.category"
              @change="applyFilters" />
            <label
              class="btn btn-default"
              :for="'category-' + category"><i></i> {{ categoryLabelMap[category] }}</label>
          </div>
        </div>
      </div>
    </div>

    <div :class="{ 'fullscreen': isFullscreen, 'h-table-card': true }">
      <div class="h1-table h-padding-top-l">
        <div class="h-d-flex h-space-between h-margin-right-l h-margin-left-l">
          <div>
            <div class="h-d-flex h-align-items-center">
              <h3 class="h-h3 h-primary_shade_1 h-align-items-center">
                Patients Alerts
              </h3>
              <InputField
                id="fullscreen"
                type="toggle"
                label="Full view"
                class="h-margin-left-s h-toggle-no-mb"
                v-model="isFullscreen" />
            </div>
          </div>
          <div class="h-d-flex h-align-items-center align-items-center w-300">
            <input type="search" placeholder="Search" class="h-input " v-model="textSearch" @input="handleInput()" />
            <div class="h-relative h-line-height-1 dropdown h-add-dropdown">
              <a class="dropdown-toggle arrow-none" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false" style="background: white">
                <i class="h-h3  h-secondary_shade_1 healenticons-download h-margin-left-m"> </i>
              </a>
              <div ref="collectionDropdown" class="dropdown-menu assessment-dropdown"></div>
            </div>
          </div>
        </div>
        <!-- DataTable -->
        <table id="PatientsActivity" ref="table" class="table dataTable w-100 h-table-log h-table-activity">
          <thead class="thead-light">
            <tr class="activity-header">
              <th>ID</th>
              <th>Action</th>
              <th>Date</th>
              <th>Patient Name</th>
              <th>Priority</th>
              <th>Status</th>
              <th>Category</th>
              <th>Topic</th>
              <th>Nurse action</th>
              <th>Facility</th>
            </tr>
          </thead>
        </table>

        <!-- Other Components -->
        <PatientProfileInDrawer ref="PatientProfileInDrawer" :key="client.id" />
        <PushNotificationDialog ref="pushNotificationDialog" />
        <EditAlertDialog ref="editAlertDialog" @updated="refresh" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import PushNotificationDialog from './components/PushNotificationDialog';
import EditAlertDialog from './components/EditAlertDialog';
import PatientProfileInDrawer from '../../../all_patients/components/PatientProfileInDrawer';
import eventBus from '../../../../../event-bus';
import InputField from '@/components/InputField.vue';


export default {
  data() {
    return {
      isFullscreen: false,
      client: {},
      dataTable: null,
      textSearch: '', // For search input

      // Filter fields
      filters: {
        priority: ['critical'],  // Default selected priorities
        alertStatus: ['active'],  // Default selected statuses
        category: ['BHI Alerts'],  // Default selected categories
      },

      // Options for checkboxes
      priorityOptions: ['critical', 'high', 'medium', 'low'],
      statusOptions: ['active', 'resolved', 'wont_fix'],
      categoryOptions: ['BHI Alerts', 'Follow-up Required', 'CCM Alerts'],  // Add 'CCM Alerts'

      // Category labels for UI display
      categoryLabelMap: {
        'BHI Alerts': 'BHI Alerts',
        'Follow-up Required': 'RPM/RTM Alerts',
        'CCM Alerts': 'CCM Alerts'
      },
      statusLabelMap: {
        active: 'Open',
        resolved: 'Resolved',
        wont_fix: 'Ignore'
      }
    };
  },

  computed: {
    filtersPriority() {
      return this.filters.priority.length > 0
        ? this.filters.priority.map(p => p.charAt(0).toUpperCase() + p.slice(1)).join(', ')
        : 'None';
    },
    filtersStatus() {
      return this.filters.alertStatus.length > 0
        ? this.filters.alertStatus.map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(', ')
        : 'None';
    },
    filtersCategory() {
      return this.filters.category.length > 0 ? this.filters.category.join(', ') : 'None';
    },
  },
  methods: {
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
    },

    applyFilters() {
      const appliedFilters = JSON.parse(JSON.stringify(this.filters));

      if (appliedFilters.category.includes('CCM Alerts')) {
        appliedFilters.category = appliedFilters.category.filter(cat => cat !== 'CCM Alerts');
        appliedFilters.category.push('CCM Interest', 'CCM Enroll');
      }

      this.refresh(appliedFilters);
    },

    refresh(filters) {
      if (this.dataTable) {
        const payload = {
          ...this.dataTable.ajax.params(),
          customFilters: filters,
        };

        this.dataTable.ajax.reload((json) => {
          json.customFilters = payload.customFilters;
        }, false);
      }
    },
    removeFilter(type, value) {
      this.filters[type] = this.filters[type].filter(item => item !== value);
      this.applyFilters();
    },
    handleInput() {
      if (!this.textSearch) {
        this.clearSearch();
      } else {
        this.search();
      }
    },
    search() {
      if (this.dataTable) {
        this.dataTable.search(this.textSearch).draw();
      } else {
        console.error('DataTable not initialized!');
      }
    },
    clearSearch() {
      this.textSearch = '';
      if (this.dataTable) {
        this.dataTable.search('').draw();
      }
    },
  },

  mounted() {
    const that = this;
    const allColumns = [
      { name: 'id', visible: false },
      {
        name: 'action',
        searchable: false,
        orderable: false,
        render: (data, type, row, meta) => {
          const rowIndex = meta.settings.json.data.indexOf(row);
          return `
                    <div class="text-center">
                      <a
                        href="javascript:void(0);"
                        class="mr-2 showPushNotificationDialog"
                        data-id="${row[0]}"
                      >
                        <i class="fas fa-bell text-primary font-16"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="mr-2 showEditAlertDialog"
                        data-id="${row[0]}"
                      >
                        <i class="healenticons-edit text-primary font-16"></i>
                      </a>
                    </div>
                  `;
        },
      },
      {
        name: 'due_date',
        searchable: false,
        render: (data) => (data ? moment(data).format('MMM D, YYYY h:mm a') : null),
      },
      {
        name: 'client_name',
        render: (data, type, row, meta) => {
          const rowIndex = meta.settings.json.data.indexOf(row);
          if (rowIndex === -1) return data;
          const clientId = meta.settings.json.metadata[rowIndex].client_id;
          return `<a href="javascript:void(0);" class="OpenProfileInDrawer" data-id="${clientId}">${that.$strUtils.escapeHtml(data)}</a>`;
        },
      },
      {
        name: 'priority',
        searchable: true,
        orderable: true,
        render: (data) => {
          const value = {
            critical: 'danger',
            high: 'high',
            medium: 'warning',
            low: 'low',
          }[data] || 'info';
          return `<span class="badge badge-soft-${value}">${data}</span>`;
        },
      },
      {
        name: 'alert_status',
        searchable: false,
        orderable: true,
        render: (data) => {
          return this.statusLabelMap[data] || 'None';
        },
      },
      {
        name: 'status',
        searchable: true,
        orderable: true,
        render: (data) => {
          if (data === 'Follow-up Required') {
            return 'RPM/RTM Alerts';
          }
          return data || 'None';
        },
      },
      {
        name: 'topic',
        className: 'h-td-wr minwidth600',
        render: (data) => {
          if (!data) return null;
          const truncatedText = data.length > 150 ? `${data.substring(0, 150)}...` : data;
          return `<span data-tooltip="${data}">${truncatedText}</span>`;
        },
      },
      {
        name: 'nurse_action',
        searchable: false,
        className: 'h-td-wr minwidth300',
        render: (data) => {
          if (!data) return null;
          const truncatedText = data.length > 80 ? `${data.substring(0, 80)}...` : data;
          return `<span data-tooltip="${data}">${truncatedText}</span>`;
        },
      },
      {
        name: 'client_facility',
        render: (data, type, row, meta) => {
          const rowIndex = meta.settings.json.data.indexOf(row);
          if (rowIndex === -1) return data;
          const clientId = meta.settings.json.metadata[rowIndex].client_id;
          return `<a href="javascript:void(0);" class="OpenProfileInDrawer" data-id="${clientId}">${that.$strUtils.escapeHtml(data)}</a>`;
        },
      },
    ];

    this.dataTable = $(this.$refs.table).DataTable({
      order: [[2, 'desc']],
      ordering: true,
      processing: true,
      serverSide: true,
      pageLength: 100,
      ajax: (data, callback, tableSettings) => {
        const payload = {
          ...data,
          customFilters: { ...this.filters },
          // columns: tableSettings.columns
        };

        if (payload.customFilters.category.includes('CCM Alerts')) {
          payload.customFilters.category = payload.customFilters.category.filter(cat => cat !== 'CCM Alerts');
          payload.customFilters.category.push('CCM Interest', 'CCM Enroll');
        }

        this.$api.getClientsActivity(payload).then((response) => {
          tableSettings.json = response;
          callback(response);
        });
      },
      searching: true,
      dom: '<""<""tr>><"h-row h-space-between h-align-item-center h-padding-bottom-l h-padding-top-l"lip>',
      buttons: {
        buttons: ['copy', 'excel', 'csv', 'print'],
      },
      columns: allColumns,
    });

    this.$nextTick(() => {
      $(this.$refs.table).on('init.dt', () => {
        this.dataTable.buttons().container().appendTo(this.$refs.collectionDropdown);
      });
    });

    $(this.$refs.table).on('click', '.OpenProfileInDrawer', async (e) => {
      const id = $(e.target).closest('a').attr('data-id');
      await this.$refs.PatientProfileInDrawer.show(id);
    });

    eventBus.$on('alertStatusModified', () => {
      this.dataTable.ajax.reload(null, false);
    });

    $(this.$refs.table).on('click', '.showPushNotificationDialog', (e) => {
      const id = $(e.target)
        .closest('a')
        .attr('data-id');
      const data = this.dataTable.data().toArray();
      const row = data.find((entry) => {
        return entry[0] === id;
      });
      const headers = $(this.$refs.table)
        .find('th')
        .map((index, el) => {
          return $(el)
            .text()
            .trim();
        })
        .toArray();
      headers.unshift('id');
      headers.pop();
      const idParts = id.split('-');
      const resultData = {
        metadata: {
          id: parseInt(idParts[1], 10),
          entryType: idParts[0],
        },
      };
      headers.forEach((header, index) => {
        resultData[header] = row[index];
      });
      that.$refs.pushNotificationDialog.show(resultData);
    });


    $(this.$refs.table).on('click', '.showEditAlertDialog', (e) => {
      const id = $(e.target).closest('a').attr('data-id');
      const data = this.dataTable.data().toArray();
      const row = data.find((entry) => {
        return entry[0] === id;
      });

      const headers = $(this.$refs.table)
        .find('th')
        .map((index, el) => {
          return $(el).text().trim();
        })
        .toArray();
      headers.unshift('id');
      headers.pop();

      const idParts = id.split('-');
      const resultData = {
        metadata: {
          id: parseInt(idParts[1], 10),
          entryType: idParts[0],
        },
      };

      headers.forEach((header, index) => {
        resultData[header] = row[index];
      });

      this.$refs.editAlertDialog.show(resultData);
    });

  },

  beforeDestroy() {
    if (this.dataTable) {
      this.dataTable.destroy(true);
      this.dataTable = null;
    }
  },

  components: {
    PushNotificationDialog,
    EditAlertDialog,
    PatientProfileInDrawer,
    InputField
  },
};
</script>
