<template>
  <div>
    <div
      id="headerInput"
      class="h-d-flex h-bg-w h-margin-top-l h-margin-left-l h-margin-right-l h-padding-top-l
       h-padding-left-l h-padding-right-l h-padding-bottom-l"
    >
      <div
        v-if="reportTypeChoices.length > 1"
        id="selectReport"
        class="h-col-desktop-2 h-col-mobile-12"
      >
        <label class="h-label">Report type:</label>
        <InputField
          type="select"
          id="reportSelector"
          :choices="reportTypeChoices"
          v-model="reportType"
        />
      </div>
      <HospitalReportForm
        v-if="reportType === 'Provider report'"
        @allReportsPushStatusChanged="allReportsPushStatusChanged"
        v-on:startProcessing="startProcessingHospitalReport"
        v-on:endProcessing="endProcessingHospitalReport"
      />
      <PatientReportForm
        v-if="reportType === 'Billing report'"
        @dateRange="setDateRange"
        @downloadReport="downloadReport"
        v-on:startProcessing="startProcessing"
        v-on:endProcessing="endProcessing"
      />
      <StatisticReport v-if="reportType === 'Statistic report'" />
      <div v-if="!reportTypeChoices.length" class="text-muted">
        <big>No reports available.</big>
      </div>
      <MonthlyReportForm
        v-if="reportType === 'Monthly report'"
        @allReportsPushStatusChanged="allReportsPushStatusChanged"
      />
    </div>

    <div
      v-if="this.isSuperUser"
      class="h-bg-w h-margin-left-l h-margin-right-l h-margin-top-l"
    >
      <ReadingCountOfPatients
        v-if="reportType === 'Billing report'"
        :dateStart="dateStart"
        :dateEnd="dateEnd"
        :hospitalId="hospitalId"
        ref="readingCountOfPatients"
      />
    </div>

    <div v-if="reportTypeChoices.length > 1" class="h-bg-w h-margin-left-l h-margin-right-l">
      <ProtocolComplience
        v-if="reportType === 'Protocol Compliance'"
        :filterValues="filterValues"
        @updateFilterValues="updateFilterValues"
      />
      <PtCompliance
        v-if="reportType === 'PT Compliance'"
        :filterValues="filterValues"
        @updateFilterValues="updateFilterValues"
      />
      <EducationCompliance
        v-if="reportType === 'Education Compliance'"
        :filterValues="filterValues"
        @updateFilterValues="updateFilterValues"
      />
      <ClientEngagement v-if="reportType === 'Patient engagement'" />
      <Progress v-if="reportType === 'Progress report'" />
      <HospitalReportTable
        ref="hospitalReportTable"
        v-if="reportType === 'Provider report'"
      />
      <BillingReportTable
        v-if="reportType === 'Billing report'"
        ref="billingReportTable"
        @reportPushStatusChanged="reportPushStatusChanged"
      />
      <StatisticReportTable v-if="reportType === 'Statistic report'" />
      <MonthlyReportTable v-if="reportType === 'Monthly report'" />
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.globalTopMargin {
  margin-top: 2.5vw;
}
</style>

<script>
import settings from '@/settings.js';

import InputField from '@/components/InputField';

import EducationCompliance from './components/EducationCompliance';
import ProtocolComplience from './components/ProtocolComplience';
import PtCompliance from './components/PtCompliance';
import ClientEngagement from './components/ClientEngagement';
import Progress from './components/progress/Progress';
import MonthlyReportForm from './components/monthly_report/MonthlyReportForm';
import MonthlyReportTable from './components/monthly_report/MonthlyReportTable';
import HospitalReportForm from './components/hospital_report/HospitalReportForm';
import HospitalReportTable from './components/hospital_report/HospitalReportTable';
import PatientReportForm from './components/BillingReport/PatientReportForm';
import BillingReportTable from './components/BillingReport/BillingReportTable';
import StatisticReport from './components/StatisticsReport/StatisticReport';
import StatisticReportTable from './components/StatisticsReport/StatisticReportTable';
import ReadingCountOfPatients from '@/components/reports/ReadingCountOfPatients';

export default {
  data() {
    const reportTypeChoices = [[null, '--- Select report type ---']];
    if (settings.isSuperUser()) {
      reportTypeChoices.push('Billing report');
      reportTypeChoices.push('Provider report');
      reportTypeChoices.push('Statistic report');
      reportTypeChoices.push('Monthly report');
    } else if (
      settings.hasRole('nurse_administrator')
      || settings.hasRole('system_administrator')
    ) {
      reportTypeChoices.push('Billing report');
      reportTypeChoices.push('Provider report');
    } else if (settings.hasRole('nurse')) {
      reportTypeChoices.push('Provider report');
    }

    return {
      isSuperUser: settings.isSuperUser,
      reportType: 'Billing report',
      reportTypeChoices,
      filterValues: {
        selectedCareProtocolId: null,
      },
      dateStart: null,
      dateEnd: null,
      hospitalId: null,
    };
  },

  methods: {
    updateFilterValues(newFilteres) {
      Object.assign(this.filterValues, newFilteres);
    },

    getReportTypeFromConst(value) {
      return {
        'progress-reports': 'Progress report',
      }[value];
    },
    startProcessingHospitalReport() {
      this.$refs.hospitalReportTable.startProcessing();
    },
    endProcessingHospitalReport() {
      this.$refs.hospitalReportTable.endProcessing();
    },
    startProcessing() {
      this.$refs.billingReportTable.startProcessing();
    },

    endProcessing() {
      this.$refs.billingReportTable.reload();
    },

    setDateRange({ dateStart, dateEnd, hospitalId, showOnlyEligible }) {
      this.$refs.billingReportTable.load(dateStart, dateEnd, hospitalId, showOnlyEligible);
      this.dateStart = dateStart;
      this.dateEnd = dateEnd;
      this.hospitalId = hospitalId;
    },

    downloadReport() {
      this.$refs.billingReportTable.download();
    },

    allReportsPushStatusChanged() {
      console.log('+');
      this.$refs.hospitalReportTable.reload();
    },
    reportPushStatusChanged() {
      this.$refs.readingCountOfPatients.load();
    },
  },

  mounted() {
    window.execAfterInitMenuInterface(() => {
      window.initActiveMenu('left-navigation-link>hospital>reports');
    });

    $('#reportSelector').bind('setReportType', (_a, reportType, _b) => {
      this.reportType = this.getReportTypeFromConst(reportType);
    });
  },

  components: {
    InputField,
    EducationCompliance,
    ProtocolComplience,
    PtCompliance,
    ClientEngagement,
    Progress,
    HospitalReportForm,
    HospitalReportTable,
    PatientReportForm,
    BillingReportTable,
    StatisticReport,
    StatisticReportTable,
    MonthlyReportForm,
    MonthlyReportTable,
    ReadingCountOfPatients,
  },
};
</script>
