<template>
  <div>
    <div class="h-mobile-padding-left-l h-mobile-padding-right-l h-margin-bottom-xxl">
      <div class="h-col-desktop-8 h-col-screen-m h-col-mobile-12 h-margin-auto h-margin-top-l">
        <div class="h-col-desktop-12 h-col-mobile-12">
          <div v-if="hospital">
            <h3 class="h-h3 h-primary_shade_1 h-margin-bottom-xm">
              Select Facility
            </h3>
            <InputField
              type="select"
              v-model="hospitalId"
              :choices="hospitalChoices"
              :disabled="loading" />
          </div>
        </div>
        <hr v-if="!loading" class="h-hr h-margin-bottom-l h-margin-top-l" />
        <div v-if="!loading">
          <section>
            <div class="h-space-between h-align-items-center">
              <h3 class="h-h3 h-primary_shade_1">Care protocols</h3>
              <div class="h-d-flex">
                <button
                  style="width: max-content"
                  class="h-btn h-btn-accent h-margin-left-m h-align-items-center"
                  @click="openAddCareProtocolDialog"
                  v-show="isSectionOpen(0)">
                  <i
                    style="line-height: 1; font-size: 10rem"
                    class="h-secondary_shade_5 healenticons-plus h-btn-with-icon h-padding-right-s"></i>Add care protocols
                </button>
                <button
                  style="width: max-content"
                  class="h-btn h-btn-accent h-margin-left-m h-align-items-center"
                  @click="toggleSection(0)">
                  <i
                    :class="[
                      'h-arrow-down',
                      'h-secondary_shade_5',
                      'healenticons-arrow-ios-forward-fill',
                      'h-btn-with-icon',
                      { up: isSectionOpen(0) }
                    ]"></i>
                  {{ isSectionOpen(0) ? "Collapse" : "Expand" }}
                </button>
              </div>
            </div>
            <transition name="slide">
              <div class="h1-table h-w-100" v-show="isSectionOpen(0)">
                <table class="table dataTable h-noborder-input">
                  <thead class="thead-light">
                    <tr>
                      <th width="100%">Name</th>
                      <th width="5%">Action</th>
                      <th width="5%">Default</th>
                    </tr>
                  </thead>

                  <tbody v-if="hospital.care_protocols.length !== 0">
                    <tr v-for="c of hospital.care_protocols" :key="c.id || c.uuid">
                      <td>
                        {{ c.name }}
                      </td>
                      <td>
                        <i
                          class="fas fa-trash-alt text-danger font-16 cursor-pointer"
                          title="Remove"
                          @click="removeCareProtocol(c)"></i>
                      </td>
                      <td class="h-text-center">
                        <button
                          v-if="!c.isDefault"
                          type="button"
                          class="h-btn-bordered h-margin-right-s h-btn-height20 h-btn-fill-accent"
                          @click="setDefaultCareProtocol(c)">Set default</button>
                        <span v-else>Default</span>
                      </td>
                    </tr>
                  </tbody>

                  <tbody v-if="hospital.care_protocols.length === 0">
                    <tr>
                      <td colspan="1000" class="text-center">No entries</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </transition>
          </section>

          <hr class="h-hr h-margin-bottom-l h-margin-top-l" />
          <section>
            <div class="h-space-between h-align-items-center">
              <h3 class="h-h3 h-primary_shade_1">Forms</h3>
              <div class="h-d-flex">
                <button
                  style="width: max-content"
                  class="h-btn h-btn-accent h-margin-left-m h-align-items-center"
                  @click="openNewForm"
                  v-show="isSectionOpen(7)">
                  <i
                    style="line-height: 1; font-size: 10rem"
                    class="h-secondary_shade_5 healenticons-plus h-btn-with-icon h-padding-right-s"></i>Add Form
                </button>
                <button
                  style="width: max-content"
                  class="h-btn h-btn-accent h-margin-left-m h-align-items-center"
                  @click="toggleSection(7)">
                  <i
                    :class="[
                      'h-arrow-down h-secondary_shade_5 healenticons-arrow-ios-forward-fill ' +
                      'h-btn-with-icon',
                      { up: isSectionOpen(7) }
                    ]"></i>{{ isSectionOpen(7) ? "Collapse" : "Expand" }}
                </button>
              </div>
            </div>
            <transition name="slide">
              <div class="h1-table h-w-100" v-show="isSectionOpen(7)">
                <table class="table dataTable h-noborder-input">
                  <thead class="thead-light">
                    <tr>
                      <th width="25%">Title</th>
                      <th width="5%">Created</th>
                      <th width="5%">Status</th>
                      <th width="5%">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(form, index) in forms" :key="index">
                      <td>{{ form.title }}</td>
                      <td>{{ formatDate(form.created) }}</td>
                      <td>{{ form.status }}</td>
                      <td>
                        <i
                          class="fas fa-pen h-secondary_shade_1 font-16 cursor-pointer"
                          title="Edit"
                          @click="editForm(form)"></i>
                        <i
                          class="fas fa-trash-alt
                          text-danger
                          font-16 cursor-pointer
                          h-padding-left-xm"
                          title="Delete"
                          @click="openDeleteFormDrawer(form)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </transition>
          </section>
          <hr class="h-hr h-margin-bottom-l h-margin-top-l" />
          <section>
            <div class="h-space-between h-align-items-center">
              <h3 class="h-h3 h-primary_shade_1">Campaigns</h3>
              <div class="h-d-flex">
                <button
                  style="width: max-content"
                  class="h-btn h-btn-accent h-margin-left-m h-align-items-center"
                  @click="openNewCampaign"
                  v-show="isSectionOpen(6)">
                  <i
                    style="line-height: 1; font-size: 10rem"
                    class="h-secondary_shade_5 healenticons-plus h-btn-with-icon h-padding-right-s"></i>Add Campaign
                </button>
                <button
                  style="width: max-content"
                  class="h-btn h-btn-accent h-margin-left-m h-align-items-center"
                  @click="toggleSection(6)">
                  <i
                    :class="[
                      'h-arrow-down h-secondary_shade_5 healenticons-arrow-ios-forward-fill ' +
                      'h-btn-with-icon',
                      { up: isSectionOpen(6) }
                    ]"></i>{{ isSectionOpen(6) ? "Collapse" : "Expand" }}
                </button>
              </div>
            </div>
            <transition name="slide">
              <div class="h1-table h-w-100" v-show="isSectionOpen(6)">
                <table class="table dataTable h-noborder-input">
                  <thead class="thead-light">
                    <tr>
                      <th width="25%">Title</th>
                      <th width="5%">Topic</th>
                      <th width="5%">Trigger</th>
                      <th width="5%">Cadence</th>
                      <th width="5%">Status</th>
                      <th width="5%">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(campaign, index) in campaigns" :key="index">
                      <td>{{ campaign.title }}</td>
                      <td>{{ campaign.topic }}</td>
                      <td>{{ campaign.trigger }}</td>
                      <td>{{ campaign.cadences.length }}</td>
                      <td>{{ campaign.status }}</td>
                      <td>
                        <i
                          class="fas fa-pen
                          h-secondary_shade_1
                          font-16 cursor-pointer"
                          title="Delete"
                          @click="editCampaign(campaign)"></i>
                        <i
                          class="fas fa-trash-alt
                          text-danger font-16
                          cursor-pointer
                          h-padding-left-xm"
                          title="Delete"
                          @click="openDeleteDrawer(campaign)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </transition>
          </section>
          <hr class="h-hr h-margin-bottom-l h-margin-top-l" />
          <section>
            <div class="h-space-between h-align-items-center">
              <h3 class="h-h3 h-primary_shade_1">Working hours</h3>
              <div class="h-d-flex">
                <button
                  style="width: max-content"
                  class="h-btn h-btn-accent h-margin-left-m h-align-items-center"
                  @click="addWorkingHours"
                  v-show="isSectionOpen(1)">
                  <i
                    style="line-height: 1; font-size: 10rem"
                    class="h-secondary_shade_5 healenticons-plus h-btn-with-icon h-padding-right-s"></i>Add shift
                </button>
                <button
                  style="width: max-content"
                  class="h-btn h-btn-accent h-margin-left-m h-align-items-center"
                  @click="toggleSection(1)">
                  <i
                    :class="[
                      'h-arrow-down',
                      'h-secondary_shade_5',
                      'healenticons-arrow-ios-forward-fill',
                      'h-btn-with-icon',
                      { up: isSectionOpen(1) }
                    ]"></i>
                  {{ isSectionOpen(1) ? "Collapse" : "Expand" }}
                </button>
              </div>
            </div>
            <transition name="slide">
              <div v-show="isSectionOpen(1)" class="h1-table h-w-100">
                <table class="table dataTable h-noborder-input">
                  <thead class="thead-light">
                    <tr>
                      <th width="5%">Week day</th>
                      <th width="5%">Start time*</th>
                      <th width="5%">End time*</th>
                      <th width="5%">Description</th>
                      <th width="5%">Action</th>
                    </tr>
                  </thead>

                  <tbody v-if="hospital.working_hours.length !== 0">
                    <tr v-for="wh of hospital.working_hours" :key="wh.id || wh.uuid">
                      <td>
                        <InputField
                          type="select"
                          v-model="wh.week_day"
                          :choices="[
                            'Sunday',
                            'Monday',
                            'Tuesday',
                            'Wednesday',
                            'Thursday',
                            'Friday',
                            'Saturday'
                          ]"
                          dense />
                      </td>
                      <td>
                        <InputField type="time" v-model="wh.start_time" dense />
                      </td>
                      <td>
                        <InputField type="time" v-model="wh.end_time" dense />
                      </td>
                      <td>
                        <InputField
                          type="text"
                          placeholder="Enter description"
                          v-model="wh.description"
                          dense />
                      </td>
                      <td>
                        <i
                          class="fas fa-trash-alt text-danger font-16 cursor-pointer"
                          title="Remove"
                          @click="removeWorkingHours(wh)"></i>
                      </td>
                    </tr>
                  </tbody>

                  <tbody v-if="hospital.working_hours.length === 0">
                    <tr>
                      <td colspan="1000" class="text-center">No entries</td>
                    </tr>
                  </tbody>
                </table>

                <div v-if="hospital.working_hours.length !== 0">
                  <h6 class="h-h6 h-primary_shade_1">
                    * Your Local time is used.
                  </h6>
                  <h6 class="h-h6 h-primary_shade_1">
                    ** If you have a break during a day, use two entries for the same day, e.g. one
                    entry for 8:00am to 1:00pm and another for 2:00pm to 7:00pm.
                  </h6>
                </div>
              </div>
            </transition>
          </section>
          <hr class="h-hr h-margin-bottom-l h-margin-top-l" />
          <section>
            <HospitalPhones
              :errors="errors.phones || {}"
              :phones="hospital.phones"
              :isOpen="isSectionOpen(2)"
              @toggleSection="toggleSection(2)" />
          </section>
          <hr class="h-hr h-margin-bottom-l h-margin-top-l" />
          <section>
            <HospitalEmails
              :errors="errors.emails || {}"
              :emails="hospital.emails"
              :isOpen="isSectionOpen(3)"
              @toggleSection="toggleSection(3)" />
          </section>
          <hr class="h-hr h-margin-bottom-l h-margin-top-l" />
          <section>
            <HospitalLocations
              :errors="errors.locations || {}"
              :locations="hospital.locations"
              :isOpen="isSectionOpen(4)"
              @toggleSection="toggleSection(4)" />
          </section>
          <hr class="h-hr h-padding-bottom-l h-margin-top-l" />
          <section>
            <div class="h-space-between h-align-items-center">
              <h3 class="h-h3 h-primary_shade_1">Settings</h3>
              <div class="h-d-flex">
                <button
                  style="width: max-content"
                  class="h-btn h-btn-accent h-margin-left-m h-align-items-center"
                  @click="toggleSection(5)">
                  <i
                    :class="[
                      'h-arrow-down',
                      'h-secondary_shade_5',
                      'healenticons-arrow-ios-forward-fill',
                      'h-btn-with-icon',
                      { up: isSectionOpen(5) }
                    ]"></i>
                  {{ isSectionOpen(5) ? "Collapse" : "Expand" }}
                </button>
              </div>
            </div>
            <transition name="slide">
              <div class="h-w-100" v-show="isSectionOpen(5)" style="margin: 5rem 0;">
                <InputField
                  id="json-textarea"
                  type="textarea"
                  v-model="settingsJSON"
                  class="textarea-editor h-margin-right-s mb-10 mt-10"></InputField>
              </div>
            </transition>
          </section>
          <hr class="h-hr h-margin-bottom-l h-margin-top-l" />
          <section>
            <div class="h-space-between h-align-items-center h-margin-bottom-l">
              <h3 class="h-h3 h-primary_shade_1">Push SFTP reports</h3>
              <div class="h-d-flex">
                <button
                  style="width: max-content"
                  class="h-btn h-btn-accent h-margin-left-m h-align-items-center"
                  @click="toggleSection(8)">
                  <i
                    :class="[
                      'h-arrow-down',
                      'h-secondary_shade_5',
                      'healenticons-arrow-ios-forward-fill',
                      'h-btn-with-icon',
                      { up: isSectionOpen(8) }
                    ]"></i>
                  {{ isSectionOpen(8) ? "Collapse" : "Expand" }}
                </button>
              </div>
            </div>
            <transition name="slide">
              <div v-show="isSectionOpen(8)">
                <InputField
                  type="checkbox"
                  v-model="hospital.is_push_sftp_reports_enabled"
                  id="hospital.is_push_sftp_reports_enabled"
                  label="Push SFTP reports enabled" />

                <div class="h-row" style="margin-top: 10rem">
                  <div
                    class="h-input-w49 h-desktop-margin-right-2 h-col-mobile-12 h-margin-bottom-l">
                    <label class="h-label">SFTP URL:</label>

                    <InputField type="text" v-model="hospital.sftp_url" />
                  </div>

                  <div class="h-input-w49 h-col-mobile-12 h-margin-bottom-l">
                    <label class="h-label">SFTP PORT:</label>

                    <InputField type="number" v-model="hospital.sftp_port" placeholder="22" />
                  </div>

                  <div class="h-input-w49 h-desktop-margin-right-2 h-col-mobile-12">
                    <label class="h-label">SFTP user:</label>

                    <InputField
                      type="text"
                      v-model="hospital.sftp_user"
                      autocomplete="new-password" />
                  </div>

                  <div class="h-input-w49 h-col-mobile-12">
                    <label class="h-label">SFTP password (not loading in browser):</label>

                    <InputField
                      type="password"
                      v-model="hospital.sftp_password"
                      autocomplete="new-password" />
                  </div>
                </div>
              </div>
            </transition>
            <hr class="h-hr h-padding-bottom-l h-margin-top-l" />
          </section>

        </div>
      </div>

      <SelectCareProtocolDialog ref="SelectCareProtocolDialog" @confirmed="getHospitals" />
      <Campaign
        ref="Campaign"
        @new-campaign-added="addNewCampaign"
        @update-campaign="updateCampaign"
        :editCampaignData="selectedCampaign" />
      <DeleteCampaign ref="DeleteCampaign" @delete-campaign="deleteCampaign" />
      <Form
        ref="Form"
        @new-form-added="addNewForm"
        @update-form="updateForm"
        :editFormData="selectedForm" />
      <DeleteForm ref="DeleteForm" @delete-form="deleteForm" />
      <DeleteCrateProtocolDialog
        ref="DeleteCrateProtocolDialog"
        @protocolIdDeleted="getHospitals" />
      <SetDefaultCrateProtocolDialog
        ref="SetDefaultCrateProtocolDialog"
        @protocolIdSetDefault="getHospitals" />
    </div>
    <div
      v-if="!loading"
      class="h-fixed-save h-text-center h-padding-top-l h-padding-bottom-l h-bg-w">
      <button type="button" class="h-btn h-btn-accent" @click="save">
        Save
      </button>
    </div>
    <Loader v-if="loading" />
  </div>
</template>

<script>
import settings from '@/settings.js';
import Loader from '@/components/Loader';
import InputField from '@/components/InputField';
import HospitalPhones from './HospitalPhones';
import HospitalEmails from './HospitalEmails';
import HospitalLocations from './HospitalLocations';
import SelectCareProtocolDialog from '@/views/settings/care_protocols/components/SelectCareProtocolDialog';
import DeleteCrateProtocolDialog from '@/views/settings/care_protocols/components/DeleteCrateProtocolDialog';
import SetDefaultCrateProtocolDialog from '@/views/settings/care_protocols/components/SetDefaultCrateProtocolDialog';
import Campaign from './campaign/Campaign';
import DeleteCampaign from './campaign/DeleteCampaign';
import Form from './form/Form';
import DeleteForm from './form/DeleteForm';

export default {
  data() {
    return {
      loading: true,
      errors: {},
      hospitalId: null,
      hospital: null,
      hospitalsMap: {},
      hospitalChoices: [],
      openSectionIndex: -1,
      openSections: [],
      campaigns: [],
      settingsJSON: '',
      forms: [
        {
          id: 1,
          title: 'New Patient Registration',
          created: '2023-09-15',
          status: 'Inactive',
          questionsJSON: JSON.stringify([
            {
              question: 'First Name',
              type: 'text',
              id: 'first_name',
            },
            {
              question: 'Last Name',
              type: 'text',
              id: 'last_name',
            },
            {
              question: 'Date of Birth',
              type: 'date',
              id: 'dob',
            },
            {
              question: 'Gender',
              type: 'dropdown',
              id: 'gender',
              options: [
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
                { label: 'Prefer not to say', value: 'prefer_not_to_say' },
              ],
            },
            {
              question: 'Address',
              type: 'text_area',
              id: 'address',
            },
            {
              question: 'Contact Number',
              type: 'text',
              id: 'contact_number',
            },
            {
              question: 'Emergency Contact Name',
              type: 'text',
              id: 'emergency_contact_name',
            },
            {
              question: 'Emergency Contact Relationship',
              type: 'text',
              id: 'emergency_contact_relationship',
            },
            {
              question: 'Emergency Contact Number',
              type: 'text',
              id: 'emergency_contact_number',
            },
            {
              question: 'Insurance Provider',
              type: 'text',
              id: 'insurance_provider',
            },
            {
              question: 'Insurance Policy Number',
              type: 'text',
              id: 'insurance_policy_number',
            },
            {
              question: 'Do you have any known allergies?',
              type: 'dropdown',
              id: 'known_allergies',
              options: [
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
              ],
            },
            {
              question: 'If yes, please list them:',
              type: 'text_area',
              id: 'allergy_list',
              conditional: {
                question_id: 'known_allergies',
                value: 'yes',
              },
            },
            {
              question: 'Preferred Language',
              type: 'dropdown',
              id: 'preferred_language',
              options: [
                { label: 'English', value: 'english' },
                { label: 'Spanish', value: 'spanish' },
                { label: 'Other', value: 'other' },
              ],
            },
            {
              question: 'Consent to Treatment',
              type: 'checkbox',
              id: 'consent_to_treatment',
              label: 'I hereby give my consent to receive medical treatment as necessary.',
            },
          ]),
        },
        {
          id: 2,
          title: 'New Patient Intake',
          created: '2024-01-01',
          status: 'Active',
          questionsJSON: JSON.stringify([
            {
              question: 'Full Name',
              type: 'text',
              id: 'full_name',
            },
            {
              question: 'Date of Birth',
              type: 'date',
              id: 'dob',
            },
            {
              question: 'Gender',
              type: 'dropdown',
              id: 'gender',
              options: [
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
                { label: 'Other', value: 'other' },
              ],
            },
            {
              question: 'Contact Number',
              type: 'text',
              id: 'contact_number',
            },
            {
              question: 'Email Address',
              type: 'text',
              id: 'email',
            },
            {
              question: 'Current Medications',
              type: 'text_area',
              id: 'current_medications',
            },
            {
              question: 'Allergies (if any)',
              type: 'text_area',
              id: 'allergies',
            },
            {
              question: 'Past Medical History',
              type: 'text_area',
              id: 'medical_history',
            },
            {
              question: 'Family Medical History',
              type: 'text_area',
              id: 'family_medical_history',
            },
            {
              question: 'Reason for Visit',
              type: 'text_area',
              id: 'reason_for_visit',
            },
          ]),
        },
        {
          id: 3,
          title: 'Established Patient Intake',
          created: '2023-10-10',
          status: 'Active',
          questionsJSON: JSON.stringify([
            {
              question: 'Have your personal details changed since your last visit?',
              type: 'yes_no',
              id: 'personal_details_change',
            },
            {
              question: 'Please update your contact number:',
              type: 'text',
              id: 'contact_number',
            },
            {
              question: 'Have you experienced any of the following symptoms since your last visit?',
              type: 'multiple_choice',
              id: 'new_symptoms',
              options: [
                { label: 'Headache', value: 'headache' },
                { label: 'Dizziness', value: 'dizziness' },
                { label: 'Nausea', value: 'nausea' },
                { label: 'None of the above', value: 'none' },
              ],
            },
            {
              question: 'Is there a change in your medication?',
              type: 'yes_no',
              id: 'medication_change',
            },
            {
              question: 'Please list any new medications you are taking:',
              type: 'text_area',
              id: 'new_medications',
            },
            {
              question: 'Rate your overall health since the last visit:',
              type: 'scale',
              id: 'health_rating',
              scale: {
                min: 1,
                max: 5,
                start: 3,
                min_label: 'Worse',
                max_label: 'Better',
              },
            },
          ]),
        },
      ],
      selectedCampaign: null,
      selectedForm: null,
    };
  },

  watch: {
    hospitalId() {
      this.onHospitalSwitch();
    },
  },

  methods: {
    formatDate(dateStr) {
      const date = new Date(dateStr);
      return date.toLocaleDateString('en-US', {
        month: 'short', // abbreviated month name
        day: '2-digit',
        year: '2-digit',
      });
    },
    toggleSection(index) {
      const sectionIndex = this.openSections.indexOf(index);
      if (sectionIndex > -1) {
        this.openSections.splice(sectionIndex, 1);
      } else {
        this.openSections.push(index);
      }
    },

    isSectionOpen(index) {
      return this.openSections.includes(index);
    },
    async getHospitals() {
      try {
        const hospitals = await this.$api.getHospitalsList({
          withProtocols: true,
          withCareProtocolSpecificIndicator: true,
        });
        this.hospitalChoices = hospitals
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((hospital) => {
            this.hospitalsMap[hospital.id] = hospital;

            return {
              value: hospital.id,
              title: hospital.name,
            };
          });
        if (!this.hospitalId) {
          this.hospitalId = hospitals.length > 0 ? hospitals[0].id : null;
        }
        this.onHospitalSwitch();
      } catch (e) { }
    },

    onHospitalSwitch() {
      this.hospital = JSON.parse(JSON.stringify(this.hospitalsMap[this.hospitalId]));
      this.settingsJSON = JSON.stringify(this.hospital.settings, null, 2);
      this.hospital.working_hours.forEach((wh) => {
        if (wh.__fixed) return;
        wh.__fixed = true;
        wh.start_time = this.$dateUtils.toLocalTime(`2010-01-01 ${wh.start_time}`, 'HH:mm:ss');
        wh.end_time = this.$dateUtils.toLocalTime(`2010-01-01 ${wh.end_time}`, 'HH:mm:ss');
      });
      this.errors = {};
    },
    openNewCampaign() {
      this.selectedCampaign = null;
      this.$refs.Campaign.show();
    },
    addNewCampaign(campaignData) {
      this.campaigns.push(campaignData);
    },
    updateCampaign(campaignId, updatedCampaignData) {
      const index = this.campaigns.findIndex(c => c.id === campaignId);
      if (index !== -1) {
        this.$set(this.campaigns, index, updatedCampaignData);
      }
      this.selectedCampaign = null;
    },
    cancelEdit() {
      this.selectedCampaign = null;
    },
    editCampaign(campaign) {
      this.selectedCampaign = campaign;
      console.log(campaign);
      this.$refs.Campaign.show(campaign);
    },
    openDeleteDrawer(campaign) {
      this.$refs.DeleteCampaign.openDrawer(campaign);
    },
    deleteCampaign(campaignToDelete) {
      this.campaigns = this.campaigns.filter(campaign => campaign.title !== campaignToDelete.title);
    },

    openNewForm() {
      this.selectedForm = null;
      this.$refs.Form.show();
    },
    addNewForm(formData) {
      this.forms.push(formData);
    },
    updateForm(formId, updatedFormData) {
      const index = this.forms.findIndex(c => c.id === formId);
      if (index !== -1) {
        this.$set(this.forms, index, updatedFormData);
      }
      this.selectedForm = null;
    },
    cancelEditForm() {
      this.selectedForm = null;
    },
    editForm(form) {
      this.selectedForm = form;
      this.$refs.Form.show(form);
    },
    openDeleteFormDrawer(form) {
      this.$refs.DeleteForm.openDrawer(form);
    },
    deleteForm(formId) {
      this.forms = this.forms.filter(form => form.id !== formId);
    },

    addWorkingHours() {
      this.hospital.working_hours.push({
        uuid: this.$uuid.v4(),
        week_day: 'Monday',
        start_time: '08:00',
        end_time: '19:00',
        description: '',
      });
    },

    removeWorkingHours(wh) {
      const index = this.hospital.working_hours.indexOf(wh);
      if (index !== -1) {
        this.hospital.working_hours.splice(index, 1);
      }
    },

    async save() {
      this.loading = true;
      const hospital = JSON.parse(JSON.stringify(this.hospital));
      hospital.working_hours.forEach((wh) => {
        wh.start_time = this.$dateUtils.toUtcTime(`2010-01-01 ${wh.start_time}`, 'HH:mm:ss');
        wh.end_time = this.$dateUtils.toUtcTime(`2010-01-01 ${wh.end_time}`, 'HH:mm:ss');
      });
      hospital.campaigns = this.campaigns;
      try {
        hospital.shortcutSettings = JSON.parse(this.settingsJSON);
      } catch (e) {
        $.notify('Invalid JSON format in hospitall settings, please review', {
          position: 'top center',
          className: 'error'
        });
        this.loading = false;
        return;
      }
      console.log(hospital);
      const response = await this.$api.post(
        `${settings.BACKEND_URL}/api/v-nurse/hospital/update`,
        null,
        hospital,
      );
      if (response.status === 'ok') {
        this.errors = {};
        await this.getHospitals();

        $.notify('Hospital changes saved.', {
          position: 'top center',
          className: 'success',
        });
      } else {
        if (response.errors) {
          this.errors = response.errors;
        }
        $.notify('Failed to save hospital changes.', {
          position: 'top center',
          className: 'error',
        });
      }
      this.loading = false;
    },

    openAddCareProtocolDialog() {
      this.$refs.SelectCareProtocolDialog.show(this.hospitalId);
    },
    removeCareProtocol(cp) {
      this.$refs.DeleteCrateProtocolDialog.show(cp.id, this.hospitalId);
    },

    setDefaultCareProtocol(cp) {
      this.$refs.SetDefaultCrateProtocolDialog.show(cp.id, this.hospitalId);
    },
  },

  async mounted() {
    window.execAfterInitMenuInterface(() => {
      window.initActiveMenu('left-navigation-link>hospital_settings');
    });

    if (
      !settings.hasRole('system_administrator') &&
      !settings.hasRole('nurse_administrator') &&
      !settings.isSuperUser()
    ) {
      this.$router
        .push({
          name: 'index',
        })
        .catch(() => { });
      return;
    }
    await this.getHospitals();
    const response = await this.$api.getHospitalCampaigns(this.hospitalId);
    this.campaigns = response.data;
    this.loading = false;
  },

  components: {
    InputField,
    Loader,
    HospitalPhones,
    HospitalEmails,
    HospitalLocations,
    SelectCareProtocolDialog,
    DeleteCrateProtocolDialog,
    SetDefaultCrateProtocolDialog,
    Campaign,
    DeleteCampaign,
    Form,
    DeleteForm,
  },
};
</script>
