<template>
  <div v-resize:debounce="onResize">
    <div
      class="h-bg-w h-padding-top-l
     h-padding-bottom-xl h-margin-bottom-l h-padding-left-l h-padding-right-l ">
      <div class="h-space-between h-align-items-center h-margin-bottom-m">
        <span
          v-if="hifOrProgress"
          data-tooltip="Healent Factor Index"
          :class="['h-h1', this.currentActual > 40 ? 'h-dangerous' : 'h-accent']">{{ `${this.currentActual.toFixed(1)} fs` }}
          <span v-if="shouldShowHint" class="h-info-icon h-dangerous">
            <i
              class="healenticons-with-alerts"
              data-tooltip="This factor may be subject to inaccuracy due to incomplete data."></i>
          </span>
        </span>
        <span v-if="!hifOrProgress" class="h-h2 h-primary_shade_1">
          {{ "Progress" }} <span class="h-h4 h-secondary_shade_1"> {{ "Last 30 days" }} </span>
        </span>
        <div class="h-d-flex h-align-items-center">
          <div :class="{ 'h-d-none': this.hide }" class="fc-button-group" @click="toggleSwitch()">
            <label
              class="left-bar"
              :class="{ toggleActive: hifOrProgress }"
              data-tooltip="Healent Factor Index Chart">
              {{ "HFI" }}
            </label>
            <label
              class="right-line"
              :class="{ toggleActive: !hifOrProgress }"
              data-tooltip="Progress Chart">
              {{ "Progress" }}
            </label>
          </div>
          <i
            @click="toggleHide()"
            style="font-size: 16rem;"
            :class="{ 'healenticons-path-4034': this.hide, 'healenticons-path-4035': !this.hide }"
            class="h-primary_shade_1  h-margin-left-l h-cursor-pointer"
            :data-tooltip="tooltipText"
            aria-hidden="true"></i>
        </div>
      </div>
      <div v-if="hifOrProgress" class="h-hfi-chart">
        <Chart
          :class="{ 'h-d-none': this.hide }"
          key="chartOptions1"
          ref="highcharts"
          :options="chartOptions1"></Chart>
        <div class="h-margin-top-m h-d-flex">
          <div
            class="h-w50 h-border-right-1 h-padding-right-l h-d-flex flex-column"
            style="gap: 10rem">
            <div class="h-margin-bottom-s h-fs-switcher">
              <div
                class="h-fs-btn"
                @click="toggleIsFullJourney"
                :class="{ active: !isFullJourney }">
                <h5 class="h-h4" data-tooltip="Data of last 30 days">30 days</h5>
              </div>
              <div
                class="h-actual-btn"
                @click="toggleIsFullJourney"
                :class="{ active: isFullJourney }">
                <h5 class="h-h4" data-tooltip="Data of entire journey">Entire journey</h5>
              </div>
            </div>
            <template v-for="(item, index) in sortedItems">
              <FactorItem
                :item="item"
                :key="index"
                @viewDetails="viewDetails"
                :isFullJourney="isFullJourney" />
            </template>
          </div>
          <div class="h-w50 h-padding-left-l">
            <div class="h-h4 h-primary_shade_1 h-d-flex h-margin-bottom-l">
              {{ "Tips" }}
              <div class="h-info-icon h-padding-left-s">
                <i class="healenticons-with-alerts h-secondary_shade_1" data-tooltip="AI-Powered recommendations derived from analyzing
                  the patient's behavior, vitals, and readings."></i>
              </div>
            </div>
            <ul class="h-tips-list">
              <li v-for="tip in tips" :key="tip.message">{{ tip.message }}</li>
            </ul>
            <div v-if="!tips.length">
              <h4 class="h-h4 h-secondary_shade_1 h-line-height-1-5">
                There are no tips available at the moment; in-depth patient information is necessary
                to provide valuable recommendations.
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!hifOrProgress" :class="{ 'h-d-none': this.hide }" class="h-progress-chart">
        <Chart key="chartOptions2" :options="chartOptions2" ref="chart"></Chart>
        <div class="text-center h-margin-top-m">
          <a href="javascript:void(0)" @click="hideAllSeries" class="h-toogle-btn">
            {{ showAll ? "Turn all off" : "Turn all on" }}
          </a>
        </div>
      </div>
    </div>
    <div class="h-bg-w h-padding-top-l
     h-margin-bottom-l  h-padding-bottom-l" v-if="hasVisibleVitals">
      <h3 class="h-h3 h-primary_shade_1 h-padding-left-l h-margin-bottom-m">{{ "Vitals" }}</h3>
      <div class="h-d-flex">
        <div class="h-w-100 h-d-vitals-items">
          <template v-for="(item, index) in sortedVitals">
            <div class="h-w50 h-item-border-right" v-if="!shouldHideItem(item)" :key="index">
              <div class="h-padding-left-l h-padding-right-l  h-margin-top-s h-margin-bottom-s">
                <FactorItem :item="item" :key="index" @viewDetails="viewDetails" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="h-bg-w h-padding-top-l h-margin-bottom-l  h-padding-bottom-l">
      <h3 class="h-h3 h-primary_shade_1 h-padding-left-l h-margin-bottom-m">{{ "Wellness" }}</h3>
      <div class="h-d-flex">
        <div class="h-w-100 h-d-vitals-items">
          <template v-for="(item, index) in sortedWellnessItems">
            <div class="h-w50 h-item-border-right" :key="index">
              <div class="h-padding-left-l h-padding-right-l  h-margin-top-s h-margin-bottom-s">
                <FactorItem :item="item" :key="index" @viewDetails="viewDetails" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <ItemDialog ref="itemDialog" :dateStart="dateStart" :dateEnd="dateEnd" :client="client" :key="currentItemId" />
    <FilteredAssessmentDialog ref="assessmentDialog" :client="client" />
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';
import moment from 'moment';
import resize from 'vue-resize-directive';
import FactorItem from './FactorItem';
import ItemDialog from './ItemDialog';
import FilteredAssessmentDialog from '@/components/assesment_client/components/FilteredAssessmentDialog';
import eventBus from '@/event-bus';

export default {
  name: 'HealentScore',
  components: {
    FilteredAssessmentDialog,
    ItemDialog,
    FactorItem,
    Chart,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hifOrProgress: true,
      currentActual: 0,
      isFullJourney: false,
      dateStart: moment()
        .subtract(30, 'days')
        .format('YYYY-MM-DD'),
      dateEnd: moment().format('YYYY-MM-DD'),
      showAll: true,
      allIds: [],
      currentItemId: null,
      chartOptions1: {
        chart: {
          height: 550,
          type: 'area',
          spacingLeft: -25,
          marginTop: 45,
          marginBottom: 70,
          events: { render: this.addTitleToLabels },
        },
        title: {
          text: null,
        },
        credits: {
          enabled: false,
        },
        accessibility: {
          keyboardNavigation: {
            seriesNavigation: {
              mode: 'serialize',
            },
          },
        },
        xAxis: {
          lineColor: '#152145',
          lineWidth: 2,
          categories: [],
          title: {
            text: null,
          },
        },
        yAxis: {
          min: 0,
          max: 100,
          tickInterval: 10,
          gridLineDashStyle: 'dash',
          gridLineWidth: 1.5,
          gridLineColor: '#EEEFF5',
          title: {
            text: 'fs',
            align: 'high',
            rotation: 0,
            y: -25,
            x: 40,
            style: {
              color: '#152145',
            },
          },
          labels: {
            formatter() {
              return this.value === 0 ? '' : this.value;
            },
          },
        },
        plotOptions: {
          series: {
            pointStart: 0,
          },
          area: {
            fillOpacity: 0.5,
          },
        },
        series: [
          {
            name: 'Actual',
            showInLegend: false,
            type: 'line',
            data: [0],
            color: '#EE4266 ',
            lineWidth: 4,
            zIndex: 4,
            connectNulls: true,
            marker: {
              symbol: 'circle',
              radius: 1,
            },
            zones: [{ value: 40, color: '#2b5adc' }, { color: '#EE4266 ' }],
          },
          {
            name: 'Predicted',
            showInLegend: false,
            type: 'line',
            data: [],
            color: '#152145',
            lineWidth: 2,
            zIndex: 4,
            marker: {
              symbol: 'circle',
              radius: 1,
            },
          },
        ],
      },
      chartOptions2: {
        chart: {
          height: 650,
          type: 'line',
          spacingLeft: 0,
          marginTop: 15,
          spacingBottom: 0,
        },
        title: {
          text: null,
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          lineColor: '#D6D7DD',
          lineWidth: 2,
          categories: [...Array(30)]
            .map((_, i) => moment()
              .subtract(i, 'days')
              .format('DD MMM'))
            .reverse(),
          tickLength: 5,
          tickWidth: 5,
          tickColor: '#D6D7DD',
          tickInterval: 2,
        },

        yAxis: {
          type: 'logarithmic',
          gridLineWidth: 1.2,
          gridLineColor: '#D6D7DD',
          title: null,
        },
        plotOptions: {
          scatter: {
            marker: {
              radius: 5,
              symbol: 'circle',
            },
          },
          series: {
            connectNulls: true,
            pointStart: 0,
          },
          area: {
            fillOpacity: 0.5,
          },
        },
        series: [],
        legend: {
          floating: false,
          itemDistance: 30,
          verticalAlign: 'bottom',
          layout: 'horizontal',
          align: 'center',
          itemMarginBottom: 0,
          itemMarginTop: 10,

          itemStyle: {
            color: '#152145',
            fontWeight: 'normal',
          },
          symbolRadius: 10,
          symbolHeight: 10,
          symbolWidth: 10,
          symbolPadding: 10,
        },
      },
      tips: [],
      items: [],
      vitals: [],
      wellnessItems: [],
      dataIncomplete: false,
      excludedTitles: ['Nutrition'],
      hide: false,
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter(item => !item.vital);
    },
    shouldShowHint() {
      if (this.filteredItems.length === 0) {
        return false;
      }
      return !!this.filteredItems.some(item => item.trend === null);
    },
    hasVisibleVitals() {
      return this.vitals.some(item => !this.shouldHideItem(item));
    },
    sortedItems() {
      return this.items.slice().sort((a, b) => {
        const titleA = this.getReplacedTitle(a.title);
        const titleB = this.getReplacedTitle(b.title);

        return titleA.localeCompare(titleB);
      });
    },

    sortedVitals() {
      return this.vitals.slice().sort((a, b) => {
        const titleA = this.getReplacedTitle(a.title);
        const titleB = this.getReplacedTitle(b.title);
        return titleA.localeCompare(titleB);
      });
    },
    sortedWellnessItems() {
      return this.wellnessItems.slice().sort((a, b) => {
        const titleA = this.getReplacedTitle(a.title);
        const titleB = this.getReplacedTitle(b.title);

        return titleA.localeCompare(titleB);
      });
    },
    tooltipText() {
      return this.hide ? 'Show the graph' : 'Hide the graph';
    },
  },
  directives: {
    resize,
  },
  methods: {
    getReplacedTitle(title) {
      switch (title) {
        case 'GAD':
          return 'Anxiety';
        case 'ODI':
          return 'Disability';
        case 'PHQ9':
          return 'Depression';
        case 'Quality of sleep':
          return 'Quality of sleep';
        case 'BMI':
          return 'BMI';
        case 'Pain Score':
          return 'Pain Score';
        case 'Activity':
          return 'Activity';
        default:
          return title;
      }
    },
    onResize() {
      if (this.$refs.highcharts && this.$refs.highcharts.chart) {
        this.$refs.highcharts.chart.reflow();
      }
    },

    toggleIsFullJourney() {
      this.isFullJourney = !this.isFullJourney;
      this.getScoreData();
    },
    toggleSwitch() {
      this.hifOrProgress = !this.hifOrProgress;
    },
    toggleHide() {
      this.hide = !this.hide;
      this.hifOrProgress = true;
      localStorage.setItem('hideChart', this.hide.toString());
    },
    hideAllSeries() {
      const chart = this.$refs.chart.chart;
      this.showAll = !this.showAll;
      chart.series.forEach((series) => {
        series.setVisible(this.showAll, false);
      });
      this.$refs.chart.chart.redraw();
    },

    async getScoreData() {
      try {
        const data = await this.$api.getScoreData(this.client.id, this.isFullJourney);
        const {
          tips, score_data: scoreData, values, current_score: currentScore,
        } = data;
        const clientPlanStartDate = moment(this.client.plan_start_date, 'YYYY-MM-DD HH:mm:ss');
        const maxEndDate = moment(clientPlanStartDate).add(12, 'months');
        let clientPlanEndDate = moment(this.client.plan_end_date, 'YYYY-MM-DD HH:mm:ss');
        if (clientPlanEndDate.isAfter(maxEndDate)) {
          clientPlanEndDate = maxEndDate;
        }

        const monthsBetweenStartAndEnd = this.getMonthsBetweenDates(
          clientPlanStartDate,
          clientPlanEndDate,
        );
        const adjustedScoreData = monthsBetweenStartAndEnd.map((month) => {
          const monthYear = moment(month, 'MMM YYYY');
          const index = data.months.findIndex(m => moment(m, 'MMM YYYY').isSame(monthYear));

          return index !== -1 ? scoreData[index] : null;
        });
        this.tips = tips;
        this.chartOptions1.series[0].data = adjustedScoreData;
        this.chartOptions1.xAxis.categories = monthsBetweenStartAndEnd;
        this.currentActual = currentScore;
        this.items = values.filter(item => !item.vital);
        this.vitals = values.filter(item => item.vital);
      } catch (e) {
        console.log(e);
      }
    },
    isExcludedItem(item) {
      return this.excludedTitles.includes(item.title);
    },
    async getWellnessData() {
      try {
        const allWellnessItems = await this.$api.getWellnessData(this.client.id, this.isFullJourney);
        this.wellnessItems = allWellnessItems.filter(item => !this.isExcludedItem(item));
      } catch (e) {
        console.log(e);
      }
    },

    getMonthsBetweenDates(start, end) {
      const dates = [];
      const currentDate = start.clone(); // clone the start date
      while (currentDate <= end) {
        dates.push(currentDate.format('MMM.YY'));
        currentDate.add(1, 'month');
      }
      return dates;
    },

    async getRecordData() {
      try {
        const { data } = await this.$api.getRecordData({
          client_id: this.client.id,
          date_start: this.dateStart,
          date_end: this.dateEnd,
        });
        this.allIds = data.allIds;
        const categories = [...Array(30)]
          .map((_, i) => moment()
            .subtract(i, 'days')
            .format('YYYY-MM-DD'))
          .reverse();
        const titleMapping = {
          'Time spent on physical activity': 'physical activity',
          'Patient Health Questionnaire (PHQ-9)': 'PHQ-9',
        };

        this.chartOptions2.series = data.allIds.map((item) => {
          const modifiedTitle = titleMapping[item.title] || item.title;

          const line = data.data.find(d => d.item.id === item.id);
          return {
            name: modifiedTitle,
            type: 'line',
            data: line
              ? categories.map((date) => {
                const spot = line.records.find(r => r.created_date === date);
                return spot ? spot.value : null;
              })
              : [],
            lineWidth: 4,
            zIndex: 4,
            marker: {
              symbol: 'circle',
              radius: 5,
            },
          };
        });
      } catch (e) {
        console.log(e);
      }
    },
    async viewDetails(item) {
      // Assign the current item ID only if it exists
      this.currentItemId = item.current_id || null;

      this.$nextTick(async () => {
        if (item.id_type === 'CheckListItem::') {
          // Pass a default or placeholder value if item.current_id is null
          await this.$refs.itemDialog.show(item.current_id || 'default_id', item.title);
        }
        if (item.id_type === 'ClientAssesment::') {
          // Open the dialog with the item object as-is
          await this.$refs.assessmentDialog.show(item);
        }
      });
    },

    shouldHideItem(item) {
      const anId = this.allIds.find(i => i.id === `Wellness::${item.current_id}`);
      return !anId;
    },
  },
  mounted() {
    console.log(this.sortedVitals)
    const hideChart = localStorage.getItem('hideChart');
    this.hide = hideChart === 'true';
    this.getScoreData();
    this.getRecordData();
    this.getWellnessData();
    eventBus.$on(
      'drawer-closed',
      () => {
        this.getScoreData();
        this.getRecordData();
        this.getWellnessData();
      },
      this,
    );
  },
  beforeDestroy() {
    eventBus.$offOwner(this);
  },
};
</script>
